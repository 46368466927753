// brands
import shuco from "../images/production/description/brands/schuco.webp";
import alutech from "../images/production/description/brands/alutech.webp";
import sial from "../images/production/description/brands/sial.webp";
// windows
import aws70 from "../images/production/description/windows/aws_70_hi.webp";

import aws70hi from "../images/production/description/windows/PASK_AWS70.webp";
import aws70_1 from "../images/production/description/windows/aws70_1.webp";
import aws70_2 from "../images/production/description/windows/aws70_2.webp";
import aws70_3 from "../images/production/description/windows/aws70_3.webp";

import kpt74 from "../images/production/description/windows/kpt74.webp";
import kpt74_1 from "../images/production/description/windows/kpt74_1.webp";
import kpt74_2 from "../images/production/description/windows/kpt74_2.webp";
import kpt74_3 from "../images/production/description/windows/kpt74_3.webp";

import w72 from "../images/production/description/windows/W72.webp";
// doors
import ads70 from "../images/production/description/doors/ADS_70_HD.webp";
import ads70_1 from "../images/production/description/doors/ads70_1.webp";
import ads70_2 from "../images/production/description/doors/ads70_2.webp";
import ads70_3 from "../images/production/description/doors/ads70_3.webp";
import kpt74d_1 from "../images/production/description/doors/kpt74_1.webp";
import kpt74d_2 from "../images/production/description/doors/kpt74_2.webp";
import kpt74d_3 from "../images/production/description/doors/kpt74_3.webp";
// winter garden
import wg from "../images/production/description/winterg/wg.webp";
import wg1 from "../images/production/description/winterg/wg1.webp";
import wg2 from "../images/production/description/winterg/wg2.webp";
import wg3 from "../images/production/description/winterg/wg3.webp";
// ventilated
import vent from "../images/production/description/ventilated/vent.webp";
import vent_1 from "../images/production/description/ventilated/vent_1.webp";
import vent_2 from "../images/production/description/ventilated/vent_2.webp";
import vent_3 from "../images/production/description/ventilated/vent_3.webp";
// lantern
import lant from "../images/production/description/lantern/lantern.webp";
import lant_1 from "../images/production/description/lantern/lantern_1.webp";
import lant_2 from "../images/production/description/lantern/lantern_2.webp";
import lant_3 from "../images/production/description/lantern/lantern_3.webp";
// sliding
import ass80 from "../images/production/description/sliding/ass80.webp";
import ass80_1 from "../images/production/description/sliding/ass80_1.webp";
import ass80_2 from "../images/production/description/sliding/ass80_2.webp";
import ass80_3 from "../images/production/description/sliding/ass80_3.webp";

import ass70 from "../images/production/description/sliding/ass70w.webp";
import ass70_1 from "../images/production/description/sliding/ass70_1.webp";
import ass70_2 from "../images/production/description/sliding/ass70_2.webp";
import ass70_3 from "../images/production/description/sliding/ass70_3.webp";

import ass70hi from "../images/production/description/sliding/ASS_70HI.webp";
import ass70hi_1 from "../images/production/description/sliding/ass70hi_1.webp";
import ass70hi_2 from "../images/production/description/sliding/ass70hi_2.webp";
import ass70hi_3 from "../images/production/description/sliding/ass70hi_3.webp";

import ass32 from "../images/production/description/sliding/ass32.webp";
import ass32_1 from "../images/production/description/sliding/ass32_1.webp";
import ass32_2 from "../images/production/description/sliding/ass32_2.webp";
import ass32_3 from "../images/production/description/sliding/ass32_3.webp";
// canopies
import can from "../images/production/description/canopies/canopies.webp";
import can_1 from "../images/production/description/canopies/canopies1.webp";
import can_2 from "../images/production/description/canopies/canopies2.webp";
import can_3 from "../images/production/description/canopies/canopies3.webp";
// pvc
import ct70 from "../images/production/description/pvc/corona.webp";
import ct70_1 from "../images/production/description/pvc/corona_1.webp";
import ct70_2 from "../images/production/description/pvc/corona_2.webp";
import ct70_3 from "../images/production/description/pvc/corona_3.webp";

import ct70hs from "../images/production/description/pvc/ct70hs.webp";
import ct70hs_1 from "../images/production/description/pvc/ct70hs_1.webp";
import ct70hs_2 from "../images/production/description/pvc/ct70hs_2.webp";
import ct70hs_3 from "../images/production/description/pvc/ct70hs_3.webp";

import ct82hs from "../images/production/description/pvc/ct82hs.webp";
import ct82hs_1 from "../images/production/description/pvc/ct82hs_1.webp";
import ct82hs_2 from "../images/production/description/pvc/ct82hs_2.webp";
import ct82hs_3 from "../images/production/description/pvc/ct82hs_3.webp";
// facade
import fac1 from "../images/production/description/facade/fac1.webp";
import fac2 from "../images/production/description/facade/fac2.webp";
import fac3 from "../images/production/description/facade/fac3.webp";
import fw50hi from "../images/production/description/facade/fw50hi.webp";
import fw50hi_1 from "../images/production/description/facade/fw50hi_1.webp";
import fw50hi_2 from "../images/production/description/facade/fw50hi_2.webp";
import fw50hi_3 from "../images/production/description/facade/fw50hi_3.webp";
import kp50k from "../images/production/description/facade/kp50k.webp";
import kp50k_1 from "../images/production/description/facade/kp50k_1.webp";
import kp50k_2 from "../images/production/description/facade/kp50k_2.webp";
import kp50k_3 from "../images/production/description/facade/kp50k_3.webp";
import alt50 from "../images/production/description/facade/alt50.webp";
import alt50_1 from "../images/production/description/facade/alt50_1.webp";
import alt50_2 from "../images/production/description/facade/alt50_2.webp";
import alt50_3 from "../images/production/description/facade/alt50_3.webp";

export const windows = [
  {
    category: "Окна",
    header: "Окнонная система aws70",
    image: aws70,
    brandImage: shuco,
    descriptionHeader: "ОКНО AWS 70. HISCHUCО AWS 70.BS.HI",
    description: [
      "Высококачественная оконная система премиум-класса с различными вариантами исполнения",
      "Оконная система Schüco AWS 70 содержит эффективные решения для удовлетворения различных требований к функциональности окон с учетом архитектурных особенностей: высокая теплоизоляция, узкие переплеты, стильные контуры створок, а также скрытая фурнитура для окон с открыванием внутрь и наружу.",
      "Оконная система Schüco AWS 70 в себе хорошие теплотехнические показатели с уникальными преимуществами материала алюминия: долговечность и прочность, малая ширина видимой части и широкие возможности для дизайна.",
    ],
    descriptionList: [
      "Вариант: либо как створки вровень, либо как поворотно-откидное окно с воротниковой ручкой для тяжелых, больших элементов",
      "Минимальная ширина/высота изделия 470мм/540 мм",
      "Максимальная высота изделия при ширине 1000мм – 2500 мм",
      "Максимальная высота изделия при ширине 1700мм- 2100мм",
      "Толщина заполнения 40 мм",
      "Оригинальной характеристикой этого профиля является его небольшая ширина. Такое решение позволяет установить в раме стеклопакеты большей, чем стандартный вариант, площади. А это, в свою очередь, позитивно сказывается на качестве естественного освещения",
      "Возможность выбора любого типа открывания створок: распашной, поворотный, поворотно-откидной, либо откидной. По желанию заказчика одна или несколько створок могут быть глухими",
      "Обладает высокой теплоэффективностью",
      "Достаточный для комфортного существования уровень шумопоглощения",
      "Безопасность и взломоустойчивость, обеспеченная надежной системой запирания",
      "Прочность, устойчивость к механическим, весовым и ветровым нагрузкам, долговечность и простота в уходе",
      "Возможность замены отдельных комплектующих и деталей без демонтирования всего блока",
    ],
    descriptionAdditional: null,
    imageList: null,
  },
  {
    id: 1,
    category: "Окна",
    name: "Окнонная система AWS70HI",
    image: aws70hi,
    brandImage: shuco,
    descriptionHeader: "Раздвижная окнная система PASK AWS70HI",
    description: [
      "PASK означает параллельно-отставной раздвижной откидной блок. Благодаря оптимальному сочетанию раздвижной двери и поворотно-откидного окна этот блок отличается удобством и простотой в обращении. Для проветривания можно просто откинуть створку блока PASK или полностью открыть блок. Створки PASK, расположенные вровень с рамой с наружной стороны и внахлест с внутренней, обеспечивают оптимальную герметичность конструкции.",
    ],
    descriptionList: [
      "Улучшенная теплоизоляция при монтажной глубине 70 мм",
      "Минимальный размер створки ширина/высота 930 мм/ 1200мм",
      "Максимальный размер створки ширина/высота 2200 мм/2500 мм",
      "Толщина заполнения стеклопакета 40 мм",
      "Поверхность окраски в RAL более 400 цветов",
      "Максимальный вес створки 200кг",
      "Также со створками вровень, створками с открыванием наружу или в поворотно-откидном исполнении с воротковой ручкой для больших и тяжелых элементов",
      "Широко используется в частном домостоении и жилых комплексах с панорамными видами",
    ],
    descriptionAdditional: null,
    imageList: [aws70_1, aws70_2, aws70_3],
  },
  {
    category: "Окна",
    header: "Окно КПТ74",
    image: kpt74,
    brandImage: sial,
    descriptionHeader: "окно (эконом-класса) КПТ74",
    description: [
      'Система предназначена для изготовления "теплых" окон. Цвет конструкции со стороны помещения и со стороны улицы может быть разным. Створки из "теплого" профиля монтируются во все вертикальные фасадные системы. Конструкция позволяет выполнять поворот витража на любой угол от 900 до 2700. Толщина конструкции 74 мм. ',
      "Широкий ассортимент усиленных стоек позволяет выполнять непрерывное остекление фасадов.",
    ],
    descriptionList: [
      "Система предназначена для изготовления «теплых окон, основу системы составляют алюминиевые трехкамерные профили стоек и ригелей с терморазрывом 24мм",
      "Пазы под фурнитуру (поворотную, поворотно-откидную, поворотно-сдвижную и откидную) рассчитаны на изделия известных европейских производителей",
      "Толщина конструкции — 74 мм",
      "Толщина оконных створок – 83,5мм",
      "Толщина заполнения 40 мм",
      "Максимальные Размеры створок – по ширине 1200мм, по высоте 2100мм",
      "Максимальная площадь открывающихся створок 2,6 м2",
      "Максимальная масса открывающихся элементов 200кг",
      "Неограниченная свобода дизайнерских решений, 167 цветов по шкале RAL",
      "Соответствие конструкций требованиям по герметичности, звуко- и теплоизоляции\n",
      "Пригодны для остекления офисных зданий, жилых комплексов",
    ],
    descriptionAdditional: null,
    imageList: [kpt74_1, kpt74_2, kpt74_3],
  },
  {
    id: 3,
    category: "Окна",
    header: "АЛЮТЕХ ОКНО ALT W72",
    image: w72,
    brandImage: alutech,
    descriptionHeader: "Окно ALT W72",
    description: [
      "Alutech ALT W72 - профильная система из алюминия, используемая в производстве оконных блоков, а также других, более сложных конструкций, которые устанавливаются в зданиях и сооружениях с высокими требованиями к шумо- и теплоизоляции. Подобные изделия отличаются выдержанным, стильным и современным внешним видом, идеально гармонирующим с любым архитектурным решением в экстерьере. Высокая степень шумоизоляции, низкая теплопроводность, устойчивость к внешним воздействиям, ветровым, механическим и весовым нагрузкам, длительный срок безупречной службы. ",
    ],
    descriptionList: [
      "Тип профиля теплый",
      "Минимальная видимая ширина профиля 49 мм",
      "Максимальная видимая ширина профиля 118 мм",
      "Монтажная ширина 62 мм",
      "Толщина заполнения стеклопакета 34, 40 мм",
      "Максимальные размеры 1100х2300 мм",
      "Вес створки 100 кг",
      "Применяется при остеклении как офисных зданий, жилых комплексов и частного домостроения",
    ],
    descriptionAdditional: null,
    imageList: null,
  },
];

export const doors = [
  {
    category: "Двери",
    header: "ДВЕРЬ ADS 70 HD",
    image: ads70,
    brandImage: shuco,
    descriptionHeader: "ДВЕРЬ ADS 70 HD",
    description: [
      "Schuco ADS 70 HD - Благодаря своему большому формату Schüco Дверь ADS 70 HD (Heavy Duty) придаст любому фасаду неповторимый стиль. Особыми преимуществами данной конструкции являются прочность и долговечность, что позволяет использовать ее во входных группах с высотой светового проема до 3000 мм, в частности, в общественных зданиях с интенсивным потоком людей. Монтажная глубина 70 мм обеспечивает большую свободу при оформлении и разнообразие вариантов. Schüco Дверь ADS 70 HD предназначена для работы в условиях длительной и максимальной статической нагрузки. ",
    ],
    descriptionList: [
      "Широкий выбор решений по дизайну и окраске. Возможность выбора отделки поверхности",
      "Фурнитура двери полностью скрыта внутри конструкции",
      "Двустворчатые двери открываются на ширину до 280 см, а максимальный вес створки ограничивается 200 кг. По желанию заказчика двери могут быть оснащены сплошным профилем створки либо профилем цоколя",
      "Испытания показали, что дверные конструкции из алюминиевого профиля ADS 70HD в процессе эксплуатации могут выдерживать до 1млн. циклов открывания-закрывания",
      "Профиль отличается надежностью и характеризуется достаточно длительным сроком безотказного функционирования без проведения текущего ремонта",
      "Двери из этого профиля не подвержены коррозии и разрушению, эффективно поглощают посторонние шумы, устойчивы к влаге и ультрафиолетовому излучению, при этом имеют современный высокоэстетичный внешний вид и вполне доступны по стоимости",
      "Максимальная ширина створки – до 1400 мм",
      "Подходит для использования в качестве входных дверей в зданиях с интенсивными людскими потоками",
      "Максимальная высота створки - до 3000 мм",
    ],
    descriptionAdditional: null,
    imageList: [ads70_1, ads70_2, ads70_3],
  },
  {
    category: "Двери",
    header: "ДВЕРЬ КПТ 74",
    image: kpt74,
    brandImage: sial,
    descriptionHeader: "ДВЕРЬ КПТ 74",
    description: [
      "Профили дверной системы сконструированы по трехкамерному принципу. Для создания терморазрыва используется термовставка шириной 24 мм из полиамида. Толщина конструкции 74 мм. Применение специально разработанных профилей делает возможным поворот на угол от 90º до 270º. Двери, изготовленные из профилей системы КПТ74, могут использоваться как наружные с открыванием наружу или внутрь, правосторонние и левосторонние, в фасадах, витражах входных групп, в проёмах строительных ограждающих конструкций различных жилых, общественных, производственных зданий. Распашную створку двери можно навешивать на стойку витража, используя притвор. В качестве светопрозрачного заполнения используется стеклопакет толщиной 32 мм с обычным, тонированным или бронированным стеклом. Для непрозрачного заполнения применяется дверная панель соответствующей толщины. Двери и створки легко монтируются в витражи ",
    ],
    descriptionList: [
      "Система предназначена для изготовления «теплых» конструкций из алюминия",
      "Дверная створка с профилем СИАЛ КПТ 74 может достигать габаритов высотой максимум 2800мм, шириной максимум 1000 мм",
      "Поверхность окраски в RAL более 400 цветов",
      "Монтажная ширина рамы — 74 мм",
      "Толщина заполнения стеклопакета- 32 мм",
      "Цикл открывания дверей: внутренних- 100 000 раз, наружные 300 000 раз",
      "Двери, изготовленные из профилей системы КПТ74, могут использоваться как наружные с открыванием наружу или внутрь, правосторонние и левосторонние, в фасадах, витражах входных групп, в проёмах строительных ограждающих конструкций различных жилых, общественных, производственных зданий",
    ],
    descriptionAdditional: null,
    imageList: [kpt74d_1, kpt74d_2, kpt74d_3],
  },
  {
    category: "Двери",
    header: "ДВЕРИ ALT W72",
    image: w72,
    brandImage: alutech,
    descriptionHeader: "ДВЕРИ ALT W72",
    description: [
      "Дверная профильная система Alutech ALT W72 используется при изготовлении дверных светопрозрачных конструкций, предназначена для современных энергоэффективных зданий. Максимальные габариты изделий на порядок превышают размеры подобных изделий из пластика или дерева. Обладает повышенными звукоизоляционными и теплотехническими качествами",
    ],
    descriptionList: [
      "Высокая степень шумоизоляции, низкая теплопроводность, устойчивость к внешним воздействиям, ветровым, механическим и весовым нагрузкам, длительный срок безупречной службы",
      "Экологическая безопасность, пожаро- и влагоустойчивость",
      "Возможность выбора любого цвета из палитры RAL, а также типа покрытия (анодирование либо нанесение полимерного защитного слоя). Профили системы могут быть окрашены с внутренней и наружной стороны, что позволяет вписать конструкции в архитектуру любого фасада и использовать их в интерьере",
      "Тип профиля теплый",
      "Минимальная видимая ширина профиля 49 мм",
      "Максимальная видимая ширина профиля 118 мм",
      "Монтажная ширина двери 62 мм",
      "Заполнение стеклопакетом створки дверей 34, 40 мм",
      "Максимальные размеры 1100х2300 мм",
      "Вес створки 100 кг",
    ],
    descriptionAdditional: null,
    imageList: null,
  },
];

export const winterg = [
  {
    category: "Зимние сады",
    header: "ЗИМНИЕ САДЫ",
    image: wg,
    brandImage: shuco,
    descriptionHeader: "ЗИМНИЕ САДЫ",
    description: [
      "Компания Linzer занимается изготовлением, сборкой, установкой зимних садов с высокими показателями тепло-шумоизоляции , из сертифицированных материалов ведущих производителей — алюминиевых систем SCHUCO",
    ],
    descriptionList: [
      "Обеспечение помещения высокой теплоизоляцией обусловлено использованием терморазрыва. Другими словами, материалы для изготовления зимних садов наполнены полимерами, которые обеспечивают низкую теплопроводность",
      "Зимние сады обладают таким преимущественным показателем, как отличная шумоизоляция",
      "Зимние сады устойчивы к различным температурным перепадам",
      "Длительный срок эксплуатации зимнего сада с алюминиевым каркасом",
      "Улучшенная освещённость",
      "Частичка природы в вашем доме",
    ],
    descriptionAdditional: [
      "Каждый дом может обладать особенным интерьером, уникальным фасадным декором, но это еще не все, что может сделать то или иное имение еще более особенным. Примером может стать такая роскошь, как зимний сад. Зимние сады обеспечивают жителям мегаполисов комфорт пребывания на лоне природы, для этого достаточно открыть дверь в свой зимний сад. Технологии позволяют сделать зимний сад как в просторном пентхаусе или в загородном доме, так и в небольших пространствах городских лоджий. Площадь зимнего сада может быть абсолютно любой, под каждое пространство можно найти оптимальное решение и создать оазис уюта и свежести в доме или офисе.",
      "Мы предлагаем своим заказчикам проектирование и создание под ключ зимних садов с различными видами прозрачной кровли. Индивидуально подбирается система окон и дверей, это могут быть распашные или раздвижные светопрозрачные конструкции, оснащенные при необходимости автоматикой. Разместить зимний сад можно на крыше здания, в пристройке к дому, в отдельно стоящем строении, на крыше спортзала или бассейна, или на мансарде. Выезд к заказчику необходим для того, чтобы сделать точные замеры и учесть положение солнца относительно будущей конструкции. В проекте учитываются пожелания заказчика по цвету алюминиевых конструкций, разбивке стекла, количество открывающихся элементов. При воплощении проектов зимних садов мы используем энергосберегающие стекла ведущих производителей: Pilkington, Guardian Glass, AGC. Все зимние сады Linzer создает с учетом строительных норм и правил.\n",
      "Важно отметить, что зимние сады всегда создаются по индивидуальным размерам в соответствии с проектом. Это обеспечивает максимальную точность и плотность примыкания всех конструкционных частей. \n" +
        "Мы всегда используем специальные энергосберегающие стеклопакеты, которые частично отражают солнечные лучи, таким образом растения и интерьер в зимнем заду не пострадают от интенсивного воздействия ультрафиолетом. Кроме того, такие стеклопакеты сдерживают поток тепла летом и холод зимой. Что значительно снижает затраты на охлаждение помещения летом и отопление зимой. \n" +
        "Кроме того, зимние сады долговечны.\n" +
        "Каркас зимнего сада выполнен из прочных алюминиевых конструкций с износостойким порошковым покрытием. Внешний вид и конструктивные качества зимнего сада останутся неизменно высокими на протяжении многих десятилетий.\n",
      "Вкладывая средства в зимний сад, наши заказчики инвестируют не только в свой комфорт и здоровье. С появление зимнего сада дом или квартира значительно поднимается в цене. Благодаря такому приятному месту, ваше жильё обретет новые краски, более приятные для сердца, которые смогут не только придать очень весомый эстетический ряд домашнему комфорту, но также во много крат поднимет солидность данного дома. ",
    ],
    imageList: [wg1, wg2, wg3],
  },
];

export const ventilated = [
  {
    category: "ВЕНТИЛИРУЕМЫЕ ФАСАДЫ",
    header: "НАВЕСНЫЕ ВЕНТИЛИРУЕМЫЕ ФАСАДЫ",
    image: vent,
    brandImage: null,
    descriptionHeader: "НАВЕСНЫЕ ВЕНТИЛИРУЕМЫЕ ФАСАДЫ",
    description: [
      "Навесные вентилируемые фасады (вентфасады)– современный эффективный способ выполнить тепло и звукоизоляцию строительного объекта. Используя фасадные системы, вы можете выполнить даже самую сложную отделку фасада, в соответствии с вашими вкусами. Благодаря своей технологии, вентилируемый фасад может быть использован для облицовки различных объектов от небольшого киоска до крупного торгового центра.\n" +
        "Учитывая непростые климтические условия Дальневосточного региона, вентилируемый фасад зарекомендовал себя как оптимальный в соотношении цена - качество - эксплуатация.",
    ],
    descriptionList: [
      "Улучшение звукоизоляции, надежности, долговечности и энергоэффективности любого типа здания",
      "Долгий безремонтный срок службы фасадов, не менее 50 лет из нержавеющей или оцинкованной стали с полимерным покрытием",
      "Все элементы навесных вентилируемых фасадных системы удовлетворяют прочностным, коррозионностойким и противопожарным требованиям",
      "Использование единого типа направляющей под все виды облицовочного материала",
      "Фасадные системы рассчитаны для применения в промышленных городах с агрессивной средой и повышенной ветровой нагрузкой, а также приморских зонах",
      "Монтаж навесного вентилируемого фасада в любое время года",
      "Вся продукция сертифицирована, имеет технические свидетельства (ТС) и техническую оценку (ТО), Альбом технических решений (АТР), а также экспертные заключения о пожарной безопасности навесных фасадных систем и облицовочных материалов фасадов.",
    ],
    descriptionAdditional: [
      "Несущая конструкция (подсистема). Изготавливается из нержавеющей или оцинкованной стали с полимерным покрытием.",
      "Утеплитель. Обеспечивает снижение затрат на отопление внутренних помещений, повышение звукоизоляции, дополнительную защиту несущих стен, стыков и соединений.",
      "Облицовочный материал для фасадов (композитные панели и фасадные кассеты, металлосайдинг (сайдинг металлический), фасадный керамогранит, натуральный и искусственный камень, объемная керамика, фиброцемент, металлокассеты (аллюминиевые или стальные).",
      "Цена на навесные вентилируемые фасады, монтаж фасадных систем зависит от цены комплектующих, сложности работ, площади здания и рассчитывается для каждого Заказчика индивидуально. Расчет несущей способности навесной фасадной системы проводится с учетом строительных норм.",
    ],
    imageList: [vent_1, vent_2, vent_3],
  },
];

export const lantern = [
  {
    category: "ЗЕНИТНЫЕ ФОНАРИ",
    header: "ЗЕНИТНЫЕ ФОНАРИ",
    image: lant,
    brandImage: null,
    descriptionHeader: "ЗЕНИТНЫЕ ФОНАРИ",
    description: [
      "Современное техническое решение для естественного освещения, вентиляции и дымоудаления пpoмышлeнных здaний, cклaдoв, цeхoв, тopгoвых цeнтpoв и т.д.",
      "Использование алюминия существенно облегчает подобное остекление, что неизменно сказывается на его популярности. Существенным образом снижается нагрузка на несущие конструкции, что позволяет применять такие зенитные фонари без переустройства кровли частного дома.",
    ],
    descriptionList: [
      "Прочная и герметичная конструкция",
      "Долговечность используемых материалов",
      "Создание условий естественного освещения",
      "Современный внешний вид",
      "Применения для дымоудаления и вентиляции",
      "Материал отличается устойчивостью к воздействию коррозии",
      "Длительное время сохраняет свой первоначальный внешний вид",
    ],
    descriptionAdditional: [
      "Зенитные фонари могут быть различной форы и типа. В зависимости от архитектурного стиля здания они могут иметь уникальный дизайн и визуальное оформление. Сложность конструкции зенитных фонарей так же зависит от здания, где они устанавливаются и, конечно же, пожеланий заказчика.\n" +
        "Основой для зенитных фонарей может быть алюминиевая конструкция, либо металлоконструкция. Зенитные фонари могут устанавливаться на различные пo углу наклона и формат основания.\n" +
        "Сегодня такие зенитные фонари с успехом используются, как владельцами частных домов. Подобное архитектурное решение одновременно функционально и позволяет существенно повысить удобство использования помещения.",
    ],
    imageList: [lant_1, lant_2, lant_3],
  },
];

export const sliding = [
  {
    category: "РАЗДВИЖНЫЕ СИСТЕМЫ",
    header: "ASS 80 FD.HI",
    image: ass80,
    brandImage: shuco,
    descriptionHeader: "ASS 80 FD.HI",
    description: [
      "Эта система представляет собой комбинированную раздвижную дверь и поворотно-откидное окно. Качественные ролики и направляющие обеспечивают легкость хода и простоту в обращении. Терморазрывы, заполненные специальным материалом, обеспечивают хорошую теплоизоляцию, и герметичность делают эту систему пригодной для использования в средней полосе России.",
    ],
    descriptionList: [
      "Улучшенная теплоизоляция при монтажной глубине 70 мм",
      "Минимальный размер створки ширина/высота 930 мм/ 1200мм",
      "Максимальный размер створки ширина/высота 2200 мм/2500 мм",
      "Толщина заполнения стеклопакета 38, 52 мм",
      "Поверхность окраски в RAL более 400 цветов",
      "Максимальный вес створки 200кг",
      " Также со створками вровень, створками с открыванием наружу или в поворотно-откидном исполнении с воротковой ручкой для больших и тяжелых элементов",
    ],
    descriptionAdditional: [
      "PASK означает параллельно-отставной раздвижной откидной блок. Благодаря оптимальному сочетанию раздвижной двери и поворотно-откидного окна этот блок отличается удобством и простотой в обращении. Для проветривания можно просто откинуть створку блока PASK или полностью открыть блок. Створки PASK, расположенные вровень с рамой с наружной стороны и внахлест с внутренней, обеспечивают оптимальную герметичность конструкции.",
    ],
    imageList: [ass80_1, ass80_2, ass80_3],
  },
  {
    category: "РАЗДВИЖНЫЕ СИСТЕМЫ",
    header: "ASS 70.FD",
    image: ass70,
    brandImage: shuco,
    descriptionHeader: "ASS 70.FD",
    description: [
      "Система дверей типа гармошка, имеет индивидуально настраиваемую звукоизоляцию, высокую прочность и взломостойкость, износостойкое покрытие и большой выбор цвета окрашивания. Подходит для балконов и лоджий. Управляя одной рукой, благодаря специальным ручкам с замком, Вы легко сложите створку, передвигая ее по специальным бесшумным роликам.",
      "Максимальная экономия места при открывании в сравнении с распашными системами",
    ],
    descriptionList: [
      "Максимальные Размеры створок – по ширине/высоте 1200/3000мм",
      "Максимальная масса створки 100 кг",
      "Видимая ширина профиля – 80 мм",
      "Монтажная глубина стеклопакета 80 мм",
      "Толщина стеклопакета 34мм,40 мм",
      "Класс взломоустойчивости до WK2 / RC 2 согласно DIN EN 1627",
      "Элементы складываются вправо, влево, внутрь или наружу",
      "Изготавливается из высокопрочного лёгкого алюминия и имеет высокие показатели теплоизоляции",
      "Створки бесшумно перемещаются по направляющим и надежно удерживаются сверху и снизу. В результате визуально стирается граница между внутренним и наружным пространством",
    ],
    descriptionAdditional: null,
    imageList: [ass70_1, ass70_2, ass70_3],
  },
  {
    category: "РАЗДВИЖНЫЕ СИСТЕМЫ",
    header: "ASS 70.HI",
    image: ass70hi,
    brandImage: shuco,
    descriptionHeader: "ASS 70.HI",
    description: [
      "Это подъемно-раздвижная система с высокой степенью теплоизоляции и высокой прочностью, а двойной уплотнитель притвора еще больше увеличивает теплостойкость системы.\n" +
        "Подходит не только для раздвижных дверей и окон террасы, балкона или зимнего сада, но и является отличным выбором в использовании в офисах или ресторанах.\n" +
        "Легкая и бесшумная в движении, она оснащена полностью скрытой системой привода фурнитуры.\n" +
        "Система имеет не только современный дизайн и высокий комфорт использования, но и устойчива к взлому.",
    ],
    descriptionList: [
      "Теплоизолированные алюминиевые профили высокой прочности",
      "Интеграция устройства e-slide: полностью скрытая система привода фурнитуры",
      "Макс. ширина створки 3000 мм, высота створки - 3000 мм",
      "Профили самых разных цветов для перегородок и внешних ограждающих конструкций",
      "Площадь створок до 9 м²: высокая прозрачность и максимальное пропускание дневного света",
      "Направляющие из нержавеющей стали обеспечивают легкий и бесшумный ход",
      "Малая ширина рамы створки 88 мм",
      "Двойной уплотнитель притвора по периметру гарантирует повышенную звукоизоляцию",
      "Максимальный вес створки - 300 кг",
      "Толщина заполнения от 38,52 мм",
      "Это идеальное системное решение для объектного строительства и жилых домов повышенной комфортности",
    ],
    descriptionAdditional: [
      "Подходит для установки в офисах, гостиницах, ресторанах, элитном жилье",
    ],
    imageList: [ass70hi_1, ass70hi_2, ass70hi_3],
  },
  {
    category: "РАЗДВИЖНЫЕ СИСТЕМЫ",
    header: "ASS 32 NI",
    image: ass32,
    brandImage: shuco,
    descriptionHeader: "ASS 32 NI",
    description: [
      "Расположение элементов на трех направляющих позволяет оставлять открытыми 2/3 проема. Раздвижные конструкции идеальны в качестве входа на остекленные балконы и террасы. Дополнительные возможности — сочетание с фрамугами и парапетами — позволяют создавать различные конструкции по индивидуальным размерам, в том числе и для установки внутри помещений.",
    ],
    descriptionList: [
      "Небольшая видимая ширина створок для максимального пропускания света",
      "Алюминиевые профили специально для отделки помещений",
      "Удобное управление одной рукой благодаря специальным ручкам (с замком)",
      "Разнообразные возможности оформления благодаря сочетаемости систем, также возможны комбинации с фрамугами и парапетами",
      "Бесшумный ход благодаря роликам с подшипниками",
      "Монтажная глубина 65 мм (с двумя направляющими) или 50 мм (с тремя направляющими)",
      "Толщина стеклопакета 6 мм, 18мм",
      "Видимая ширина створки не более 69 мм",
      "До шести створок обеспечивают открывание на 2/3 от ширины элемента",
      "Максимальный вес створки до 90 кг",
      "Максимальная ширина/высота створки 1400мм/3000мм",
    ],
    descriptionAdditional: [
      "Системы не снабжены теплоизоляцией и являются экономичным и не затратным решением для раздвижных дверей внутри помещения и там, где не требуется теплоизоляция",
    ],
    imageList: [ass32_1, ass32_2, ass32_3],
  },
];

export const canopies = [
  {
    category: "КОЗЫРЬКИ И НАВЕСЫ",
    header: "КОЗЫРЬКИ И НАВЕСЫ",
    image: can,
    brandImage: null,
    descriptionHeader: "КОЗЫРЬКИ И НАВЕСЫ",
    description: [
      "Компания Linzer изготавливает конструкции светопрозрачных козырьков и навесов любой сложности из алюминия, стали, стекла, пластика и иных материалов по проектам заказчиков и собственной разработки.",
      "Стеклянные козырьки и навесы из закаленного стекла придают неповторимый, оригинальный и строгий вид зданию или входной группе. Особенно эффектно стеклянные козырьки сочетаются со стеклянными дверями или входной группой из стекла. Они надежно защищают от яркого света и осадков, придавая большее состояние комфорта.",
      "\n" +
        "Стеклянные козырьки весят намного меньше, чем похожие конструкции из бетона и металла.\n" +
        "Это снижает нагрузку на опорные конструкции и делает внешний вид входной группы намного изящнее.",
    ],
    descriptionList: [
      "Высокая прочность на давление",
      "Высокая устойчивость к царапинам и другим механическим повреждениям",
      "Отличная устойчивость к перепаду температур",
      "Высокие антикоррозийные свойства",
      "Эстетичный внешний вид",
      "Возможность производства навесов любой формы",
      " Долговечность",
    ],
    descriptionAdditional: [
      "Закаленное стекло имеет высокую прочность, и при этом оно монтируется на опоры из нержавеющей стали. К тому же стекло не подвержено воздействиям агрессивных атмосферных сред, перепаду температур, ветровым нагрузкам и т.д. Все вместе это делает козырьки и навесы из стекла одними из наиболее удачных типов конструкций для защиты входа в здание.",
      "Козырьки из стекла прекрасно пропускают свет (что особо важно в зимний период), не подвержены коррозии, не требуют особого ухода и т.д. Это делает их намного практичнее аналогичных конструкций из других материалов.",
      "И самое приятное, что восхитительный дизайн стеклянных конструкций будет не только украшать ваше здание, но и подчеркнет ваш тонкий вкус и высокий социальный статус.",
      "Проектируя для Вас стеклянные козырьки над входом в офис или над крыльцом любого другого здания, наши специалисты учитывают такие особенности как порывы ветра, падение сосулек и веток, снеговая нагрузка. Стеклянные элементы имеют многослойную структуру (триплекс). Несущие элементы, фурнитура и крепежные элементы изготовлены из высококачественной нержавеющей стали. Более надежную конструкцию нельзя купить за деньги.",
      "Для вас будет организованно изготовление стеклянных козырьков и прямых и гнутых панелей, в качестве несущих элементов могут быть использованы подпорки, растяжки или кронштейны. Вам предложат конфигурацию, наиболее подходящую для монтажа на вашем здании.",
      "И если вы изучите предлагаемые Вам цены на изготовление и монтаж козырьков из стекла, то увидите, что более оптимального сочетания доступной цены и высокого качества Вы не найдете на локальном рынке. У нас Вы можете недорого заказать стеклянные козырьки. Для получения более подробной информации свяжитесь с нашими менеджерами.",
    ],
    imageList: [can_1, can_2, can_3],
  },
];

export const pvc = [
  {
    category: "ПВХ СИСТЕМЫ",
    header: "ПВХ СИСТЕМЫ",
    image: ct70,
    brandImage: shuco,
    descriptionHeader: "CORONA CT 70",
    description: [
      "Пластиковые окна Schuco сочетают в себе высокую надежность, новаторский подход и широкий ассортимент профилей и фурнитуры. Постоянное совершенствование производственных линий позволяет получать продукцию высокого качества, соответствующую всем современным требованиям к оконным конструкциям.",
      "Наша компания является официальным представителем крупнейшего немецкого производителя профиля ПВХ «Schuco» и изготавливает окна из следующих профильных систем:",
    ],
    descriptionList: [
      "ПВХ-системы для окон и балконных дверей",
      "Монтажная глубина профиля 70 мм",
      "Пятикамерная профильная система",
      "Небольшая ширина видимой части 120 мм (стандартная комбинация профилей) обеспечивает оптимальную светопропускаемость и элегантный внешний вид конструкций",
      'Возможность поставки трех различных вариантов створки: "Classic" (со смещенной плоскостью). Толщина заполнения стеклопакета 40 мм',
      "Высококачественные уплотнители EPDM предоставлены фирмой Semperit (производство Германия)",
      "Цветовое разнообразие профилей за счет широкого выбора натуральных тонов и декоративных покрытий с имитацией дерева, а также алюминиевых накладок",
    ],
    descriptionAdditional: [
      "Все заводы компании Шуко находятся в Германии, поэтому профили и фурнитура производятся под жестким контролем. Рецептура ПВХ профиля разработана с целью максимально улучшить свойства изделия, а не для того, чтобы уменьшить себестоимость. Соблюдение оригинальной рецептуры не только на бумаге, но и в производстве позволяет создать профиль, обладающий эластичностью, прочностью и стабильностью цвета. В изготовлении профиля используются современные материалы и технологии, дружественные к окружающей среде.",
    ],
    imageList: [ct70_1, ct70_2, ct70_3],
  },
  {
    category: "ПВХ СИСТЕМЫ",
    header: "ПВХ СИСТЕМЫ",
    image: ct70hs,
    brandImage: shuco,
    descriptionHeader: "HS-CT 70",
    description: [
      "ПВХ-система Schüco Corona CT 70 HS изготавливается на основе системы Schüco Corona CT 70 с монтажной глубиной 70 мм. Усилительные профили рамы и пороги подъемно-раздвижных элементов с термическим разделением обеспечивают отличную теплоизоляцию и оптимальное прохождение изотерм.",
    ],
    descriptionList: [
      "Створка 70 мм с 5 камерами",
      "Максимальный размер ширина/высота конструкции 6500ммх2600мм",
      "Максимальная площадь остекления проема одной конструкцией",
      "Максимальное светопропускание, обеспечивающее естественное освещение помещения",
      "Противовзломность в стандартной комплектации",
      "Дополнительная детская безопасность при обращении с ручкой",
      "Вес до 400 кг. соответственно возможна установка безопасного стеклопакета",
      "Герметичность, теплосбережение",
      "Плоский, не возвышающийся над поверхностью пола порог",
      "Плавность хода и простота эксплуатации",
      "Возможность установки двухкамерного стеклопакета толщиной до 40 мм",
    ],
    descriptionAdditional: [
      "Благодаря специально разработанной системе фурнитуры возможна реализация различных типов открывания и вариантов оформления интерьера. Помимо белого цвета мы имеем возможность предложить вам широчайший спектр цветовых решений и вариантов декора, которые вы можете независимо выбирать как для использования снаружи, так и внутри помещения.",
    ],
    imageList: [ct70hs_1, ct70hs_2, ct70hs_3],
  },
  {
    category: "ПВХ СИСТЕМЫ",
    header: "ПВХ СИСТЕМЫ",
    image: ct82hs,
    brandImage: shuco,
    descriptionHeader: "HS-CT 82",
    description: [
      "Окна Schuco HS-CT 82 являются одной из самых популярных моделей продукции немецкой компании. Благодаря использованию в производстве инновационных конструктивных разработок, данная модель обрела широкий спрос на рынке и признание у клиентов, выбравших её.",
    ],
    descriptionList: [
      "Максимальный размер ширина/высота конструкции 6500ммх2800мм",
      "Створка 82 мм с 5 камерами",
      "Профиль с 7 камерами гарантирует высокий уровень надежности в работе",
      "Максимальная площадь остекления проема одной конструкцией",
      "Максимальное светопропускание, обеспечивающее естественное освещение помещения",
      "Противовзломность в стандартной комплектации",
      "Дополнительная детская безопасность при обращении с ручкой",
      "Вес до 400 кг. соответственно возможна установка безопасного стеклопакета",
      "Плоский, не возвышающийся над поверхностью пола порог",
      "Плавность хода и простота эксплуатации",
      "Отличная теплоизоляция за счёт тройной термовставки в пороге;",
      "Возможно тройное остекление до 52мм",
    ],
    descriptionAdditional: [
      "Впервые в мире найдена возможность сваривания прокатываемых в ПВХ профиль EPDM уплотнителей как для стеклопакета так и для створки рамы окна. EPDM уплотнитель является совместной разработкой концерна Schüco и Semperit. Новинка, позволила избежать жёстких загибов на угловых соединениях по всему периметру окна, в результате создаётся надежный контур уплотнения.",
    ],
    imageList: [ct82hs_1, ct82hs_2, ct82hs_3],
  },
];

export const facade = [
  {
    category: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    header: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    image: fac1,
    brandImage: null,
    descriptionHeader: "Стоечно-ригельная система остекление фасадов",
    description: [
      "Стоечно-ригельная система представляет собой ограждающую фасадную конструкцию, состоящую из металлического вертикально-горизонтального каркаса и светопрозрачного заполнения. Каркас системы формируется при помощи вертикальных профилей – стоек, к которым крепятся горизонтальные балки – ригели. Фиксация стеклопакетов к несущему каркасу осуществляется при помощи прижимных планок, на которые затем устанавливаются декоративные крышки.",
    ],
    descriptionList: [
      "Элегантный и респектабельный внешний вид здания",
      "Высокая светопроницаемость, экономия электроэнергии",
      "Визуальное расширение пространства внутри помещений",
      "Дополнительная вентиляция здания",
      "Конструкция любой формы и цвета",
      "Надежность и практичность конструкции",
      "Герметичность конструкции",
      "Комфорт людей, в помещении с естественным светом",
      "Долговечность: срок службы 50 лет",
      "Остекление фасадов большой площадью",
      "Возможность быстрого монтажа в любой сезон",
      "Экологическая безопасность",
    ],
    descriptionAdditional: [
      "Всё чаще при строительстве зданий компании используют фасадное остекление. Это объясняется тем, что стеклянный фасад позволяет не только реализовать красивый внешний вид сооружения, но и практичен изнутри – визуально увеличивает пространство, светопропускаемость помещений, делает жильё современным.\n" +
        "Такой вид фасадов широко применяется как в жилых домах, так и в бизнес-центрах, общественных и административных зданиях, а также на предприятиях. Высокие окна, стеклянные кровли полностью преображают здание снаружи и изнутри и превращают его в уникальное, неповторимое сооружение.\n" +
        "Сделав выбор в пользу фасадного остекления, собственники недвижимости добиваются двойного результата – оригинальности здания снаружи и эксклюзивности интерьера изнутри.",
    ],
    imageList: null,
  },
  {
    category: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    header: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    image: fac2,
    brandImage: null,
    descriptionHeader: "Структурное остекление фасадов",
    description: [
      "Структурную систему фасадного остекления - это современная строительная технология, которая позволяет формировать сплошную стеклянную поверхность фасадов, причем, без проявления на нем швов и стыков между панелями. Первостепенное отличие от стандартной стоечно-ригельной системы кроется в специальных элементах крепления, которые с внешней стороны здания абсолютно незаметны.",
    ],
    descriptionList: [
      "Элегантный и респектабельный внешний вид здания",
      "Высокая светопроницаемость, экономия электроэнергии",
      "Визуальное расширение пространства внутри помещений",
      "Дополнительная вентиляция здания",
      "Конструкция любой формы и цвета",
      "Надежность и практичность конструкции",
      "Герметичность конструкции",
      "Комфорт людей, в помещении с естественным светом",
      "Долговечность: срок службы 50 лет",
      "Остекление фасадов большой площадью",
      "Возможность быстрого монтажа в любой сезон",
      "Экологическая безопасность",
    ],
    descriptionAdditional: [
      "Снаружи стеклянные панели укрепляются на усиленный силиконовый герметик, благодаря которому стеклопакеты сцепляются с несущей конструкцией. Исходя из этого, отсутствует необходимость в применении прижимных планок. А вертикальные стойки с горизонтальными ригелями, как и прежде, являются основанием под стеклянные панели",
      "Следует заметить, что все открывающиеся створки тщательно скрыты, и поэтому их не будет видно на поверхности фасада. Расстояние между панелями находится в пределах 20 мм. Эффект структурного остекления фасадов зданий хорошо виден на представленных здесь фото.\n" +
        "Выбирайте структурное остекление, как одну из самых передовых технологий фасадной облицовки. Благодаря данной системе, вы сможете реально получить здание с любыми формами и пропорциями, а его фасад будет выглядеть легким и элегантным.",
    ],
    imageList: null,
  },
  {
    category: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    header: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    image: fac3,
    brandImage: null,
    descriptionHeader: "Спайдерное остекление фасадов",
    description: [
      "Спайдерное (планарное) остекление относится к категории наиболее респектабельных фасадных решений благодаря уникальному эстетическому результату. Рамы в устройстве поверхности из стеклянных панелей не используются, стеклопакеты крепятся к несущей конструкции при помощи спайдеров – специальных кронштейнов.",
    ],
    descriptionList: [
      "возможность реализации любых архитектурных замыслов, независимо от их сложности",
      "замену громоздких строений на воздушные легкие конструкции",
      "максимальную безопасность при установке закаленного стекла",
      "устойчивость к воздействию внешних факторов и окружающей среды",
      "высокую скорость монтажа, поэтому спайдерная система остекления фасадов позволяет снизить сроки строительства",
      "минимизацию несущих конструкций, что дает возможность сэкономить материалы и снизить нагрузку на здание в целом",
      "долговечность и экономичность в использовании при сравнительно небольших затратах на обслуживание",
      "простоту ремонта и своевременную замену стекол при их повреждении",
      "предельно допустимая возможность проникновения солнечного света, поэтому спайдерное остекление является причиной значительного снижения затрат на электроэнергию и отопление",
      "комфорт при эксплуатации и минимальные требования к обслуживанию",
      "благодаря герметикам, обеспечивается высокий уровень теплоизоляции и защиты от влаги.",
    ],
    descriptionAdditional: [
      "С помощью спайдерного остекления изготавливают не только фасады зданий, но и офисные перегородки, лифтовые шахты, козырьки, стеклянные ступени и ограждения. Безрамное остекление спайдерами позволяет создать панорамный вид внутри конструкции. При этом любые спайдерные системы могут быть укреплены невидимым силовым каркасом, который обеспечивает высокую надежность при видимой невесомости и хрупкости конструкции.",
      "Спайдерная технология позволяет остеклить сложные по форме поверхности - конусные и куполообразные кровли, в том числе, подвески и круглые элементы. В качестве прозрачного заполнения используются сверхпрочные архитектурные стекла, закрепленные на несущих опорах в нескольких точках.",
    ],
    imageList: null,
  },
  {
    category: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    header: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    image: fw50hi,
    brandImage: shuco,
    descriptionHeader: "СТОЕЧНО-РИГЕЛЬНАЯ СИСТЕМА FW 50+.HI",
    description: null,
    descriptionList: [
      "Минимальная ширина видимой части профилей 50 мм",
      "Конструкция с высокой теплоизаляцией",
      "Фасад FW 50+ HI позволяет акцентировать как горизонтальные, так и вертикальные линии",
      "Допустимая толщина стеклопакета в прозрачной части 34мм,46мм, 52мм",
      "Устойчивость к коррозии при полной анадировке",
      "Максимальная высота стеклопакета 2300 мм*3300 мм",
      "Фасад может быть выполнен по любому классу антивандальной, антикриминальной, антитеррористической защиты. Он может быть огнестойким (тип BF), пуленепробиваемым, взломоустойчивым, выдерживать осколки гранаты и взрывную волну",
      "В фасадную систему может быть встроен молниеотвод",
    ],
    descriptionAdditional: null,
    imageList: [fw50hi_1, fw50hi_2, fw50hi_3],
  },
  {
    category: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    header: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    image: kp50k,
    brandImage: sial,
    descriptionHeader: "СТОЕЧНО-РИГЕЛЬНАЯ СИСТЕМА КП50К",
    description: null,
    descriptionList: [
      "Толщина заполнения стеклопакета – 6, 26,34,42,52мм",
      "Ширина лицевой поверхности-50 мм",
      "Максимальный вес стеклопакета 700кг",
      "Позволяет осуществлять повороты витража на угол до 45 градусов в плане и заворот до 90 градусов на наклонную крышу",
      "Поверхность окраски в цвета RAL более 167 цветов",
      "Использовать в помещениях с повышенным уровнем влажности только при предварительной анадировке профиля",
      "Стеклопакеты- однокамерные и двухкамерные",
      "Широкая номенклатура профилей стоек позволяет изготавливать витражи высотой до 6 метров без промежуточных опор",
      "Гарантия на конструкции 5 лет",
      "Пригодны для остекления офисных зданий, жилых комплексов, а также частных квартир и коттеджей",
    ],
    descriptionAdditional: null,
    imageList: [kp50k_1, kp50k_2, kp50k_3],
  },
  {
    category: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    header: "ФАСАДНОЕ ОСТЕКЛЕНИЕ",
    image: alt50,
    brandImage: alutech,
    descriptionHeader: "СТОЕЧНО-РИГЕЛЬНАЯ СИСТЕМА ALT F50",
    description: [
      "Alutech ALT F50 - фасадная система из алюминия, состоящая из алюминиевых стоек и ригелей небольшой видимой ширины (50 мм). Такое решение обеспечивает конструкции высокий уровень проникновения света в помещение, при этом выглядит она легкой, изящной и элегантной. Используется такая система в остеклении фасадов зданий любой формы и площади.",
    ],
    descriptionList: [
      "Высокая степень теплоэффективности",
      "Оригинальная система «тепло-холод» предполагает установку вместо стеклопакета одинарного стекла и одновременное закрытие утеплителя непрозрачным материалом",
      "Внутренняя и внутренняя видимая ширина 50 мм",
      "Толщина заполнения 6,26,34,46,52 мм",
      "Максимальная масса заполнения 700 кг",
      "Использавание  однокамерных, двухкамерных стеклопакетов, а также стекла",
      "Глубина стоек и ригелей  12-270 мм",
      "Несколько типов соединения стойки и ригеля (встык, внахлест, с фрезеровкой внахлест) расширяют границы выбора",
      "Возможность создания поверхностей с изломом (вертикально-наклонных стоек), а так же наклонно-поворотных поверхностей",
      "Окраска профиля в любой оттенок из палитры RAL",
    ],
    descriptionAdditional: [
      "Использование в создании фасадной конструкции уникального сухарного профиля дает возможность справиться с любыми нестандартными задачами, такими как: обустройство поверхностей с изломом, установка в плоскости витражей стоек с возможностью поворота, оснащение фасадов наклонно-поворотными поверхностями, присоединение к фасаду наклонной конструкции, сооружение вершины многогранной пирамиды.",
    ],
    imageList: [alt50_1, alt50_2, alt50_3],
  },
];
